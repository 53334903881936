.modal-container {
  width: 100%;
  height: 100%;
  background-color: rgba(116, 82, 150, 0.5); /* Purple tint background */
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: 0.3s ease-out;
}

.modal {
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.3s ease-out;
  opacity: 1;
  transform: scale(1);
  overflow-y: auto;
}

.modal-header {
  background-color: #745296; /* Purple header */
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.modal-content {
  padding: 1rem;
}

.modal .actions {
  border-top: 1px solid #e2e8f0;
  background: #f7fafc;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.modal .actions button {
  background-color: #745296;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal .actions button:hover {
  background-color: #5a3283;
}

@media (max-width: 640px) {
  .modal {
    width: 95%;
    max-height: 95vh;
  }
}
