.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    border-radius: 5px;
  }
  
  .form__field {
    margin-bottom: 10px;
  }
  
  .form__label {
    font-weight: bold;
    color: #800080;
    margin-bottom: 5px;
  }
  
  .form__input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #800080;
  }
  
  .form__button {
    padding: 8px 15px;
    background-color: #800080;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form__button:hover {
    background-color: #600060;
  }
  